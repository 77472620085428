import { defineStore } from "pinia";
import { reactive } from "vue";

export const useTipStore = defineStore("tipStore", () => {
  const state = reactive({
    tipSet: false,
    vars: {},
  });

  function setTip(tipVars) {
    state.tipSet = true;
    state.vars = tipVars;
  }

  function clearTip() {
    state.tipSet = false;
    state.vars = {};
  }

  return {
    state,
    setTip,
    clearTip,
  };
});
